@import '../../../../assets/styles/variable';

body {
    .integrations-section {
        .integrations-container {
            padding: 0;

            .api-keys-listing-wrapper {
                padding: 20px;
                border: 1px solid #C0CCD4;

                .title-blk {
                    margin-bottom: 24px;
                    position: relative;

                    .heading1 {

                        img,
                        span {
                            vertical-align: middle;
                        }

                        img {
                            margin-right: 5px;
                        }
                    }
                }

                .api-keys-listing-table {

                    .api-key-wrapper,
                    .secret-wrapper,
                    .actions-wrapper {
                        .img-wrapper {
                            margin: -5px;
                            padding: 5px;
                            border-radius: 50%;
                            cursor: pointer;
                            @include transitionForAll($time: 0.4s, $transProp: 'ease-out');

                            &:hover {
                                @include transitionForAll($time: 0.4s, $transProp: 'ease-in');
                                background: rgba(0, 145, 179, 0.1);
                            }

                            cursor: pointer;
                        }
                    }

                    .empty-row {
                        height: 215px;
                    }
                }
            }
        }
    }

    .api-action-dialog {
        .content-wrapper {
            padding: 30px 100px;
            box-sizing: border-box;

            .content {
                .paragraph {
                    color: $color_title;

                    span {
                        font-weight: $boldFont;
                    }
                }
            }

            .footer {
                text-align: center;
                padding-top: 30px;

                li {
                    &.form-group-field {
                        margin-bottom: 0;

                        input[type="submit"] {
                            padding: 8px 18px;

                        }
                    }

                    .secondary-btn {
                        padding: 7px 21px;
                    }

                    &:first-child {
                        margin-right: 25px;
                    }
                }
            }
        }

        .MuiDialog-paper {
            max-width: 400px;
        }

        &.add-new-api-key {
            .MuiPaper-root {
                max-width: 440px;
            }

            .content-wrapper {
                padding: 30px;

                form {
                    .title {
                        margin-bottom: 16px;

                        img,
                        span {
                            vertical-align: middle;
                        }

                        img {
                            margin-right: 5px;
                        }
                    }

                    .copy-btn-wrapper {
                        position: relative;

                        .MuiInputBase-root {
                            padding-right: 80px;
                        }

                        .copy-btn-position {
                            position: absolute;
                            right: 10px;
                            top: 10px;
                        }
                    }

                    .footer {
                        padding-top: 25px;

                        li {
                            &:last-child {
                                margin-right: 0;
                            }

                            &.form-group-field {
                                input[type="submit"] {
                                    width: 80px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}