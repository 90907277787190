@import '../../../../assets/styles/variable';

body {
    .campaign-details-section {
        .campaign-details-container {
            padding: 0;

            .top-blk {
                margin-bottom: 5px;

                .back-btn {
                    cursor: pointer;

                    img {
                        margin-right: 3px;

                    }

                    .paragraph {
                        color: #C4C8C9
                    }
                }

                .button-wrapper {
                    ul {
                        text-align: right;

                        li {
                            margin-right: 20px;

                            &:last-child {
                                margin: 0;
                            }

                            .campaign-btn {
                                cursor: pointer;
                                padding: 8px 15px 8px 32px;
                                position: relative;
                                border-radius: 200px;
                                color: #fff;
                                // max-width: 150px;
                                text-align: left;

                                span {
                                    // margin-left: 35px;
                                }

                                // box-sizing: border-box;
                                &.disabled {
                                    pointer-events: none;
                                    cursor: not-allowed;
                                    opacity: 0.6;
                                }

                                display: inline-block;

                                img {
                                    position: absolute;
                                    left: 12px;
                                    top: 7px;
                                }

                                &.report {
                                    background: #FBB609;
                                    padding: 8px 17px 8px 35px;
                                }

                                &.suspend {
                                    background: #D41C54;
                                }

                                &.approve {
                                    background: #009B7C;

                                    img {
                                        top: 10px;
                                    }
                                }

                                &.reject {
                                    background: #071822;

                                    img {
                                        top: 10px;
                                        left: 14px;
                                    }
                                }

                                &.lift {
                                    background: #0091B3;
                                    padding: 8px 15px 8px 34px;

                                    img {
                                        top: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .title {
                text-align: center;
                margin-bottom: 30px;
                .heading1{
                    font-size: 22px;
                    line-height: 25px;
                    font-weight: 500;
                }
            }

            ul.basic {
                text-align: center;
                margin-bottom: 40px;
                display: flex;
                flex-direction: row;
                .border-left{
                    border-left: 1px solid #EDEEEF;
                }
                > .w-33 {
                    width: 33.3%;
                }
                .column {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 11px;
                    margin: 0 auto;
                    width:fit-content;
                }
                li{
                    margin-right: 40px;
                    .heading2{
                        font-size: 16px;
                        &.status{
                            img{
                                position: relative;
                                top: 3px;
                                margin-right: 5px;
                            }
                        }

                        span {
                            font-weight: $regularFont;
                            margin-right: 5px;

                            &.val {
                                font-weight: $boldFont;
                            }
                        }

                        font-weight: $boldFont
                    }

                    .campaign-usecase-wrapper {
                        .heading2 {
                            span {
                                &.val {
                                    cursor: pointer;
                                    font-weight: $boldFont;
                                }
                            }

                            .dropdown-arrow {
                                vertical-align: middle;
                                @include transitionForAll($time: 0.4s);
                                color: #0091B3;
                                ;

                                &.open {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }

                    &:last-child {
                        margin: 0;
                    }
                }
            }

            .details {
                border: 1px solid #C0CCD4;
                padding: 15px 20px;
                margin-bottom: 20px;

                .paragraph {
                    word-break: break-word;
                }

                .details-heading {
                    position: relative;
                    padding-bottom: 11px;
                    border-bottom: 1px solid #C0CCD4;
                    margin-bottom: 15px;

                    h3 {
                        line-height: 21px;
                    }

                    img {
                        position: absolute;
                        top: 2px;
                        left: 0;
                        width: 18px;
                    }
                }

                &.numbers {
                    padding-bottom: 10px;

                    .details-heading {
                        display: flex;
                        padding-bottom: 0;
                        margin-bottom: 0;
                        border-bottom: none;
                    }

                    .no-phoneNumbers {
                        background: #F3F4F4;
                        min-width: 150px;
                        box-sizing: border-box;
                        padding: 8px 10px;
                        margin-left: 15px;
                        margin-top: -5px;
                        text-align: center;
                    }

                    .campaign-numbers-wrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-left: 15px;
                        margin-top: -5px;

                        >button {
                            padding: 8px 17px 8px 17px;
                            font-size: 12px;
                            color: #FFFFFF;
                            background: #CACC4F;
                            border: none;
                            border-radius: 200px;
                            cursor: pointer;
                            text-transform: none;

                            &:disabled {
                                background: lightgray;
                                cursor: not-allowed;
                            }
                        }

                        >span {
                            color: #D41C54;
                        }

                        .one-number {
                            background: rgba(0, 105, 143, 0.08);
                            padding: 8px 10px;
                            color: #00698F;
                            font-weight: $mediumFont;
                            width: 150px;
                            box-sizing: border-box;

                            &.multi {
                                position: relative;
                                padding-right: 80px;

                                .dropdown-arrow {
                                    position: absolute;
                                    right: 5px;
                                    top: 3px;
                                }
                            }
                        }

                        >* {
                            margin-left: 16px;
                        }
                    }
                }

                &.numbers-provisioned {
                    .tableWrapper {
                        table {
                            thead {
                                tr th {
                                    &:first-child {
                                        width: 240px;
                                    }

                                    &:nth-child(2) {
                                        width: 180px;
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    &.empty-row {
                                        height: 240px;
                                    }
                                }
                            }
                        }
                    }
                }

                &.csp-brand-reseller {
                    .details-heading {

                        &.brand,
                        &.reseller {
                            margin-top: 25px;
                        }

                        &.reseller {
                            img {
                                width: 24px;
                                left: -2px;
                                top: -2px;
                            }
                        }
                    }
                }

                &.description {
                    .details-heading {
                        img {
                            top: 4px;
                        }
                    }
                }

                &.sample-messages {
                    .sample-message-item {
                        margin-bottom: 15px;
                        position: relative;

                        .message-count {
                            position: absolute;

                            h3 {
                                padding: 1px 4.5px 1px;
                                background: $color_yellow;
                                border-radius: 3px;
                                @include elementStyles($color: #fff,
                                    $weight: 500,
                                    $size: 12px)
                            }
                        }

                        p {
                            word-break: break-all;
                            margin-left: 30px;
                        }

                        &:last-child {
                            margin: 0;
                        }
                    }
                }

                &.attributes {
                    .details-heading {
                        img {
                            top: 4px;
                        }
                    }

                    .campaign-attribute {
                        background: rgba(0, 105, 143, 0.08);
                        ;
                        margin-bottom: 10px;

                        .view-container {
                            max-height: 40px;
                            min-height: 40px;
                            padding: 0 0 0 10px;
                        }

                        .title {
                            margin: 0;
                            text-align: left;
                        }

                        p {
                            font-weight: $mediumFont;
                            color: #00698F;

                            // padding-left: 20px;
                            img {
                                position: relative !important;
                                top: 1px !important;
                                left: 6px !important;
                            }
                        }

                        .buttons-view {
                            margin-top: -2px;
                            padding-left: 15px;

                            .view-item {
                                display: inline-block;
                                margin-right: 15px;

                                img {
                                    position: relative;
                                    top: 3px;
                                    margin-right: 5px;
                                }

                                span {
                                    font-weight: $boldFont;
                                    color: #C0CCD4;
                                }
                            }

                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                &.campaign-events {
                    .details-heading {
                        border-bottom: none;
                        padding-bottom: 0;

                        img {
                            top: 0;
                        }
                    }

                    .tableWrapper {
                        table {
                            tbody {
                                tr {
                                    &.empty-row {
                                        height: 240px;
                                    }


                                    .event-type-wrapper {
                                        position: relative;
                                        svg {
                                            position: absolute;
                                            top: -9px;
                                        }
                                        p {
                                            padding-left: 15px;
                                            font-weight: 500;
                                        }
                                    }

                                    .MuiPaper-root {
                                        background-color: transparent;
                                    }

                                    .MuiExpansionPanel-root {
                                        .MuiExpansionPanelSummary-root {
                                            padding: 0;
                                            .MuiExpansionPanelSummary-expandIcon.MuiIconButton-edgeEnd{
                                                height: 13px;
                                            }
                                        }

                                        .MuiExpansionPanelSummary-root.Mui-expanded {
                                            word-break: break-all;
                                        }

                                        .MuiExpansionPanelSummary-content {
                                            color: #68737A;
                                            margin: 0;
                                            font-size: 12px;
                                            @include transitionForAll($time: 0.3s);
                                            word-break: break-all;

                                       
                                            &.Mui-expanded {
                                                line-height: 1.5;
                                                word-break: break-word;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.carrier-status {
                    .details-heading {
                        border-bottom: none;
                        padding-bottom: 0;

                        img {
                            top: 5px;
                        }
                    }

                    .campaign-operations {
                        .carrier-item-wrapper {
                            margin-bottom: 10px;

                            &:last-child {
                                margin: 0;
                            }

                            .name {
                                height: 80px;
                                background: $color_primary;

                                p {
                                    color: #fff;
                                    font-weight: $mediumFont;
                                    text-align: center;
                                    width: 100%;
                                }
                            }

                            .label {
                                text-align: center;
                                padding: 11px 20px;
                                background: rgba(0, 105, 143, 0.1);
                                height: 40px;
                                box-sizing: border-box;

                                .paragraph {
                                    font-weight: $mediumFont;
                                    color: $color_primary;

                                    img,
                                    span {
                                        vertical-align: middle;
                                    }

                                    img {
                                        margin-right: 5px;
                                    }
                                }
                            }

                            .value {
                                text-align: center;
                                height: 40px;
                                padding: 11px 0;
                                box-sizing: border-box;
                                background: rgba(243, 244, 244, 0.75);

                                .medium {
                                    font-weight: $mediumFont;
                                }

                                p {

                                    img,
                                    span {
                                        vertical-align: middle;
                                    }

                                    img {
                                        margin-right: 5px;
                                    }

                                    span {
                                        text-transform: capitalize;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .phone-number-table {
                min-height: 44px;
                tbody {
                    tr {
                        &:nth-child(odd) {
                            .MuiExpansionPanel-root{
                                background: rgba(243, 244, 244, 0);
                            }
                        }
                        &:nth-child(even) {
                            .MuiExpansionPanel-root{
                                background: rgba(243, 244, 244, 0);
                            }
                            &:hover{
                                background: rgba(243, 244, 244, 0.5);
                            }
                        }
                        td{
                            cursor: default;
                        }
                        &:hover{
                            transform: unset;
                            background: unset;
                            box-shadow: none;
                            .MuiTableCell-body{
                                color: $color_text;
                            }
                        }
                    }
                }
            }
        }
        .secondary-color {
            color: $color_secondary;
        }
    }
}

.phone-numbers-menu {
    .MuiMenu-paper {
        min-width: 150px;
    }

    .MuiPopover-paper {
        top: 258px;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14);
        border-radius: 0;
    }

    .MuiMenu-list {
        padding: 0;
        outline: none;
        background: rgba(0, 105, 143, 0.08) !important;
    }

    .MuiMenuItem-root {
        color: #00698F !important;
        font-weight: 500 !important;
        cursor: text;
        padding-left: 10px;
    }
}

.campaign-action-form {
    color: '#fff';
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 200px;

    .modal-content {
        padding: 20px 20px 20px;
        outline: none;
        background: #fff;
        box-sizing: border-box;
        min-width: 400px;

        .title {
            text-align: center;
            margin-bottom: 15px;

            img {
                margin-right: 5px;
                position: relative;
                top: 2px;

            }
        }

        .modal-footer {
            padding: 15px 0 10px;

            .form-group-field {
                margin: 0;
            }

            .primary-btn {
                &.lift-suspension {
                    background: #0091B3;
                }

                &.report {
                    background: #FBB609;
                }

                &.suspend {
                    background: #D41C54;
                    padding: 8px 18px;
                }

                &.reject {
                    background: #071822;
                    padding: 8px 25px;
                }
            }

            .secondary-btn {
                outline: none;

                &.lift-suspension {
                    border-color: #0091B3;
                    color: #0091B3;
                    padding: 7px 44px;
                }

                &.report {
                    border-color: #FBB609;
                    color: #FBB609;
                    padding: 7px 46px;
                }

                &.suspend {
                    border-color: #D41C54;
                    color: #D41C54;
                }

                &.reject {
                    border-color: #071822;
                    color: #071822;
                }
            }
        }
    }

    &.reject {
        .modal-content {
            max-width: 400px;
            box-sizing: border-box;
        }

        p {
            &.notice {
                color: #00698F;
                padding: 15px 40px;
            }

            &.count {
                color: $color_title;
                padding: 15px 40px 20px;
            }
        }
    }
}

.campaign-subusecases-menu {
    .MuiMenu-paper {
        margin-top: 28px !important;
        border-radius: 2px;

        // box-shadow: none;
        .MuiMenu-list {
            background: #00698F;
            ;
            padding: 0;

            li {
                cursor: text;

                &:nth-child(even) {
                    background: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }
}