@import '../../../../assets/styles/variable';

body {
    .campaigns-listing-section {
        .loader-wrapper {
            text-align: center;
            padding-top: 200px;

            .MuiCircularProgress-root {
                color: #00698F;
            }
        }

        .campaigns-listing-container {
            padding: 0;
            position: relative;
            .title-block {
                display: flex;
                margin-bottom: 15px;
                h2 {
                    font-size:27px;
                    font-weight: 600;
                    color: #19262A;
                    line-height: 32px;
                }
                span {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    margin-left: 12px;
                    margin-bottom: 5px;
                    align-self: end;
                }
            }
            .filters {
                margin-top: 12px;
                .MuiAutocomplete-root {
                    width: auto;
                }
            }

            .mno-operational-status {
                position: relative;
                span {
                    font-weight: 400;
                }
            }

            .reviewPage {
                .tableWrapper {
                    table {
                        .MuiTableCell-root {
                            word-break: break-word;
                        }
                    }
                }
            }

            .bulk-actions-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                .bulk-action-button {
                    min-width: 140px;
                    box-sizing: border-box;
                    position: relative;
                    background: #00698F;
                    padding: 8px 36px;
                    // border-radius: 200px;
                    cursor: pointer;

                    img {
                        position: absolute;
                        left: 15px;
                        top: 7px;
                    }

                    span {
                        font-weight: 500;
                        color: #fff;
                        // margin-left: 30px;
                    }

                    .dropdown-arrow {
                        fill: #fff;
                        position: absolute;
                        top: 3px;
                        right: 10px;
                    }
                }

                .paragraph {
                    margin: 0 0px 0 10px;
                    font-weight: 500;
                }

                .apply-wrapper {
                    margin-left: 10px;

                    .apply-btn {
                        background: #0091B3;
                        padding: 8px 15px;
                        color: #fff;
                        font-weight: 500;
                        border-radius: 200px;
                        cursor: pointer;

                        &.disabled {
                            pointer-events: none;
                            opacity: 0.6;
                            cursor: not-allowed;
                        }
                    }
                }
            }

            .filter-applied {
                // margin-top: 15px;
            }
        }
    }
}

.bulk-actions-menu {
    .MuiMenu-paper {
        min-width: 140px;
    }

    .MuiPopover-paper {
        transform: translateY(31px);
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14);
        border-radius: 0;
    }

    .MuiMenu-list {
        outline: none;
        background: #00698F !important;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .MuiMenuItem-root {
        color: #fff !important;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 30px;
        width: 100%;
        justify-content: flex-start;

        &.selected {
            background-color: rgba(255, 255, 255, 0.5) !important;
        }
    }
}