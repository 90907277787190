@import '../../../../assets/styles/variable';

body {
    .csp-details-section {
        .csp-details-container {
            padding: 0;

            .top-blk {
                margin-bottom: 5px;

                .back-btn {
                    cursor: pointer;

                    img {
                        margin-right: 3px;

                    }

                    .paragraph {
                        color: #C4C8C9
                    }
                }

                .button-wrapper {
                    a {
                        float: right;
                    }
                }
            }

            .title {
                text-align: center;
                margin-bottom: 30px;
                .infoIcon {
                    font-size: 14px;
                }
                h3 {
                    font-size: 18px;
                    line-height: 21px;

                    span {
                        vertical-align: middle
                    }

                    img {
                        position: static !important;
                        vertical-align: middle;
                        margin-left: 5px;
                        height: 15px !important;
                    }
                }

            }

            ul.basic {
                text-align: center;
                margin-bottom: 30px;

                li {
                    margin-right: 40px;

                    .heading2 {
                        &.entityType {
                            color: $color_yellow;

                            span {
                                color: $color_title;
                            }
                        }

                        span {
                            font-weight: $regularFont;
                            margin-right: 5px;
                        }

                        font-weight: $boldFont
                    }

                    &:last-child {
                        margin: 0;
                    }
                }
            }

            .details {
                border: 1px solid #C0CCD4;
                padding: 15px 20px;
                margin-bottom: 20px;

                .details-heading {
                    position: relative;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #C0CCD4;
                    margin-bottom: 15px;

                    h3 {
                        margin-left: 23px;
                        line-height: 21px;
                    }

                    img {
                        position: absolute;
                        top: 2px;
                        left: 0;
                        width: 18px;
                    }

                    &.campaigns-listing {
                        margin-bottom: 0;
                    }
                }

                .detail-item {
                    h3 {
                        word-break: break-word;

                        span {
                            font-weight: $mediumFont
                        }
                    }
                }

                &.campaigns-listing {
                    padding-bottom: 15px;

                    .details-heading {
                        padding-bottom: 0;
                        border-color: transparent;
                    }

                    .filters {
                        margin-top: 12px;
                        .MuiAutocomplete-root {
                            width: auto;
                        }
                    }

                    .empty-row {
                        height: 240px;
                    }
                }
            }

            .mno-operational-status {
                position: relative;

                span {
                    font-weight: 400;
                }
            }
        }
    }
}