@import '../../../../assets/styles/variable';

body {
    .brands-listing-section {
        .brands-listing-container {
            padding: 0;
            .title-block {
                display: flex;
                margin-bottom: 15px;
                h2 {
                    font-size: 27px;
                    font-weight: 600;
                    color: #19262A;
                    line-height: 32px;
                }
                span{
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    margin-left: 12px;
                    margin-bottom: 5px;
                    align-self: end;
                }
            }
            .filters {
                margin-top: 12px;
                .MuiAutocomplete-root {
                    width: auto;
                }
            }

            .brands-table {
                tbody td {
                    word-break: break-all;
                }
            }
        }
    }
}