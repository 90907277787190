@import 'variable';

body {
    .flex {
        display: flex;

        &.h-center {
            justify-content: center;
        }

        &.v-center {
            align-items: center;
        }

        &.flex-column {
            flex-direction: column;
        }

        &.space-between {
            justify-content: space-between;
        }

        &.end {
            justify-content: end;
        }
    }

    .flex-1 {
        flex: 1;
    }

    .flex-2 {
        flex: 2;
    }

    .text-right {
        text-align: right;
    }

    .text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .font-weight-500 {
        font-weight: 500;
    }

    .h-100 {
        height: 100%;
    }

    .w-100 {
        width: 100%;
    }

    .h-100vh {
        height: 100vh;
    }

    .flex-1 {
        flex: 1;
    }

    .m-t {
        margin-top: 16px;
    }

    .p-l-xs {
        padding-left: 4px;
    }

    .p-r-s {
        padding-right: 8px;
    }

    .m-r-s {
        margin-right: 8px;
    }

    .pointer {
        cursor: pointer;
    }

    .header-drop-down {
        .MuiPopover-paper {
            top: 62px !important;
            right: 30px !important;
            max-width: 200px;
        }

        .MuiMenu-list {
            outline: none;
            background: #0E1F2B !important;
        }

        .MuiMenuItem-root {
            min-height: 25px !important;
            line-height: 0 !important;
            flex-flow: row;
            opacity: 0.8;
            border-bottom: 0.5px solid #0091B3;
            @include elementStyles($fontFamily: 'Roboto' !important,
                $weight: 400 !important,
                $size: 13px !important,
                $color: #FFFFFF !important);
            @include transitionForAll($time: 0.4s);

            &:hover {
                font-weight: 700 !important;
                opacity: 1;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .MuiList-padding {
            padding: 8px
        }

        .MuiMenuItem-gutters {
            padding: 15px 10px;
        }

        .MuiMenu-list {
            background: 'unset';
        }

        .MuiListItem-button:hover {
            background-color: inherit !important;
        }
    }

    //table
    .tableWrapper {
        table {
            tbody {
                tr {
                    &.link {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .pagination-wrapper {
        margin-top: 15px;
        width: 100%;
        text-align: center;

        .pagination {
            margin-top: 20px;

            ul {
                justify-content: center;

                li {
                    button {
                        margin: 0;
                        border-radius: 0;
                        @include elementStyles($fontFamily: 'Roboto', $size: 13px, $weight: 400, $color: #000, $lineHeight: 16px);
                        // opacity: 0.5;
                        background: #fff;
                        border-color: transparent;

                        .MuiSvgIcon-root {
                            fill: $color_yellow;
                            font-size: 25px
                        }

                        &.Mui-selected {
                            opacity: 1;
                            border-radius: 50%;
                            border: 1px solid $color_yellow;
                            background: rgba(202, 204, 79, 0.2);
                            ;
                            color: $color_yellow;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .filter-section-wrapper {
        .filter-button {
            position: relative;
            background: $color_yellow;
            border-radius: 200px;
            padding: 9px 15px;
            cursor: pointer;
            // margin-bottom: 20px;
            float: right;
            @include transitionForAll($time: 0.4s);

            img {
                position: absolute;
                left: 15px;
                width: 14px;
                top: 8px;
            }

            p {
                margin-left: 20px;
                font-weight: 500;
                color: #fff;
            }

            &.open {}
        }
    }

    .filter-drawer {
        .MuiBackdrop-root {
            background-color: transparent;
        }

        .MuiDrawer-paper {
            top: 80px !important;
            max-height: 540px;
            overflow-x: hidden;
            height: unset;
        }

        .filter-drawer-wrapper {
            padding: 10px;
            max-width: 250px;

            .header-block {
                .title {
                    padding: 13px 10px;
                    background: $color_yellow;
                    margin: -10px;
                    position: relative;
                    margin-bottom: 10px;

                    img {
                        position: absolute;
                        left: 10px;
                        width: 14px;
                        top: 12px;
                    }

                    p {
                        margin-left: 20px;
                        font-weight: 500;
                        color: #fff;
                    }

                    .filter-close {
                        cursor: pointer;
                        color: #FFF;
                        opacity: 0.6;
                        width: 18px;
                        position: absolute;
                        right: 11px;
                        top: 9px;
                    }
                }
            }

            .filter-item-wrapper {
                .border {
                    height: 1px;
                    margin: 0 -10px;
                    background-color: rgba(192, 204, 212, 0.5);
                    margin-bottom: 10px;
                }

                .filter-item {
                    padding-bottom: 10px;

                    &.dropdown {
                        padding-bottom: 5px;
                    }

                    // border-bottom: 1px solid rgba(192, 204, 212, 0.5);
                    position: relative;

                    &.form-group-field {
                        position: relative;
                        width: 100%;
                        margin-bottom: 0px;

                        .label {
                            p {
                                display: inline-block;
                                font-weight: 500;
                                color: $color_yellow;
                                margin-bottom: 3px;
                            }
                        }
                    }

                    .remove-icon {
                        width: 12px;
                        position: absolute;
                        right: 5px;
                        opacity: 0.6;
                        top: 22px;
                        cursor: pointer;
                        @include transitionForAll($time: 0.3s);

                        &:hover {
                            transform: scale(1.1);
                            opacity: 1;
                        }
                    }

                    .dropdown-filter {
                        max-height: 84px;

                        &::-webkit-scrollbar-track {
                            color: $color_yellow;
                            background: rgba(192, 204, 212, 0.3);
                            border-radius: 100px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: $color_yellow;
                            width: 5px;
                            height: 30px;
                        }

                        .filter-list {
                            li {
                                margin-bottom: 1px;

                                label {
                                    display: flex;
                                    align-items: flex-start;
                                }

                                .custom-checkbox {
                                    padding: 2px 5px 0px 0;

                                    &.Mui-disabled {
                                        opacity: 0.6;
                                        cursor: not-allowed;
                                    }

                                    &.Mui-checked {
                                        padding: 2px 4px 0px 1px;

                                        .MuiIconButton-label {
                                            .MuiIcon-root {
                                                img {
                                                    width: 16px;
                                                }
                                            }
                                        }
                                    }

                                    .MuiIconButton-label {
                                        .MuiIcon-root {
                                            img {
                                                width: 14px;
                                            }
                                        }
                                    }
                                }

                                .paragraph {
                                    &.disabled {
                                        pointer-events: none;
                                        opacity: 0.6;
                                        cursor: not-allowed;
                                    }

                                    cursor: pointer;
                                    position: relative;
                                    top: 4px;
                                }
                            }
                        }
                    }

                    .auto-suggestions-dropdown {
                        position: absolute;
                        width: 100%;
                        max-width: 230px;
                        max-height: 125px;
                        z-index: 999;
                        overflow: scroll;
                        margin-top: 3px;
                        border-radius: 4px;

                        ul {
                            background: #fff;
                            padding: 0;

                            li {
                                background: #00698F;
                                color: #fff;
                                font-size: 12px;
                                cursor: pointer;

                                &:hover {
                                    background: rgba(0, 105, 143, 0.9);
                                }

                                &.noOptions {
                                    cursor: text;
                                }
                            }
                        }
                    }
                }
            }

            .filter-footer {
                li {
                    margin-right: 10px;

                    &:last-child {
                        margin: 0;
                    }

                    a {
                        display: inline-block;
                    }

                    .secondary-btn {
                        padding: 5px 22px;
                    }

                    .primary-btn {
                        padding: 6px 22px;
                    }
                }
            }
        }

        &.brands {
            .MuiDrawer-paper {
                // max-height: 365px;
            }
        }

        &.csps {
            .MuiDrawer-paper {
                max-height: 281px;
            }
        }

        &.events {
            .MuiDrawer-paper {
                max-height: 365px;
            }
        }

        &.csp-campaigns,
        &.brand-campaigns {
            .MuiDrawer-paper {
                // max-height: 425px;
                max-height: 540px;

                @media (min-height: 800px) {
                    max-height: 615px;
                }

                @media (min-height: 900px) {
                    max-height: 700px;
                }

                .filter-drawer-wrapper {
                    padding-top: 50px;
                    margin-bottom: 36px;
                    padding-bottom: 0;
                    max-height: 540px;

                    @media (min-height: 800px) {
                        max-height: 615px;
                    }

                    @media (min-height: 900px) {
                        max-height: 700px;
                    }

                    overflow-y: scroll;
                    overflow-x: hidden;

                    &::-webkit-scrollbar-track {
                        background: rgba(158, 170, 179, 0.3);
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 6px;
                        background: #68737A;
                        height: 20px;
                    }

                    .header-block {
                        position: absolute;
                        top: 10px;
                        width: 230px;
                        z-index: 99;
                    }

                    .filter-footer {
                        position: absolute;
                        bottom: 0;
                        width: 260px;
                        right: 0px;
                        background: #fff;
                        padding: 10px 20px;
                    }
                }
            }
        }

        &.brand-campaigns {}

        &.campaigns {
            .MuiDrawer-paper {
                top: 80px !important;
                max-height: 540px;

                @media (min-height: 800px) {
                    max-height: 615px;
                }

                @media (min-height: 900px) {
                    max-height: 700px;
                }
            }

            .filter-drawer-wrapper {
                padding-top: 50px;
                margin-bottom: 35px;
                padding-bottom: 0;
                max-height: 540px;

                @media (min-height: 800px) {
                    max-height: 615px;
                }

                @media (min-height: 900px) {
                    max-height: 700px;
                }

                overflow: scroll;

                &::-webkit-scrollbar-track {
                    background: rgba(158, 170, 179, 0.3);
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 6px;
                    background: #68737A;
                    height: 20px;
                }

                .header-block {
                    position: absolute;
                    top: 10px;
                    width: 230px;
                    z-index: 99;
                }

                .filter-footer {
                    position: absolute;
                    bottom: 0;
                    width: 260px;
                    right: 0px;
                    background: #fff;
                    padding: 10px 20px;
                }
            }
        }
    }

    .custom-checkbox {}

    //header-bar
    header {

        width: 100%;
        height: 60px;
        // background: linear-gradient(90deg, #054751 0%, #006FA0 18.3%, #0071A0 19.2%, #0291A8 32.5%, #03A5AE 43.6%, #04ADB0 51.1%, #F5C245 100%);
        background: #fff;
        // box-shadow: 214px 6.3px 4.5px 0 rgba(7, 24, 34, 0.25);
        box-shadow: 7.009px 7.009px 5.006px rgba(175, 194, 203, 0.251);
        box-sizing: border-box;

        &.unauthenticated {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
        }

        .flex-centered {
            height: 60px !important;
            padding: 0 !important;
        }

        .header-logo {
            height: 100%;
            display: flex;
            align-items: center;
            background: #0E1F2B;
            justify-content: center;
            width: 205px;
            box-sizing: border-box;
            padding-top: 10px;

            a {

                //  height: 40px;
                img {
                    width: 180px;
                    margin-top: 5px;
                }
            }
        }

        .user-dropdown {
            // padding-right: 20px;
            position: absolute;
            right: 20px;

            ul {
                float: right;
                list-style: none;
                cursor: pointer;

                li {
                    display: inline-block;
                    margin-right: 10px;
                    @include elementStyles($fontFamily: 'Roboto',
                        $size: $font14,
                        $color: $color_title );

                    &:last-child {
                        margin-right: 6px;
                    }

                    & span {
                        font-family: 'Roboto';
                        font-weight: 700;
                    }
                }
            }
        }

        h3 {
            @include elementStyles($fontFamily: 'Roboto',
                $size: 14px,
                $color: #49535D,
                $weight: 500,
                $margin: 7px 0 0 40px,
                $lineHeight: 16px)
        }

        .header-avatar {
            position: relative;

            h2 {
                color: #fff;
                font-weight: 900;
                position: absolute;
                font-family: 'Roboto';
                top: 4px;
                right: 14px;
                font-size: 12px;
            }
        }
    }

    //side-bar
    .primary-side-menu {
        height: 100%;
        .header-logo {
            a {
                img {
                    width: 200px
                }
            }
        }
    }

    //error-boundary
    .technical-error {
        position: absolute;
        top: 50%;
        left: 56%;
        transform: translate(-50%, -50%);
        margin-top: 30px;
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;

        // margin-top: 68px;
        img {
            margin-bottom: 30px;
        }

        h3 {
            margin-bottom: 20px;
            @include elementStyles($fontFamily: 'Roboto',
                $size: 22px,
                $color: $color_title,
            );
        }

        p {
            @include elementStyles($fontFamily: 'Roboto',
                $size: $font14,
                $color: $color_text,
            );
            margin-bottom: 35px;
        }
    }

    .header-hoc-block {
        padding: 30px 30px 25px 240px;
    }

    .footer-hoc-block {
        font-size: 10px;
        font-weight: 300;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: center;
        color: $color_footer;
        padding: 25px 0;

        &.form {
            padding: 17px 30px 0px 240px;
        }

        &.csp {
            padding: 25px 30px 25px 30px
        }

        div {
            display: flex;
            justify-content: center;

            p {
                max-width: 802px;

                a {
                    cursor: pointer;
                    color: $color_footer;
                    text-decoration: underline;
                }
            }
        }
    }

    //page-not-found
    .page-not-found-section {
        .page-not-found-container {
            min-height: 100vh;
            background: $color_yellow;

            .page-not-found-wrapper {
                position: relative;
            }

            img {
                width: 120px;
                position: absolute;
                left: -130px;
                top: -65px;
            }

            .content-blk {
                h3 {
                    color: #fff;
                    font-size: 72px;
                    line-height: 72px;
                }

                p {
                    color: #fff;
                    max-width: 190px;
                    text-align: center;
                    margin-bottom: 60px;
                }

                a {
                    display: inline-block;
                }
            }
        }
    }

    .mesh-loader {
        overflow: hidden;
        height: 100%;
        width: 100%;

        &.hoc {
            >div {
                margin-left: 0px;
            }
        }
    }

    .mesh-loader .circle {
        width: 25px;
        height: 25px;
        position: absolute;
        // background: linear-gradient(90deg, #054751 0%, #006FA0 18.3%, #0071A0 19.2%, #0291A8 32.5%, #03A5AE 43.6%, #04ADB0 51.1%, #F5C245 100%);
        background: $color_primary;
        border-radius: 50%;
        margin: -12.5px;
        -webkit-animation: mesh 3s ease-in-out infinite;
        animation: mesh 3s ease-in-out infinite -1.5s;
    }

    .mesh-loader>div .circle:last-child {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }

    .mesh-loader>div {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: 100px;
    }

    .mesh-loader>div:last-child {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    @-webkit-keyframes mesh {
        0% {
            -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }

        50% {
            -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }

        50.00001% {
            -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes mesh {
        0% {
            -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }

        50% {
            -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }

        50.00001% {
            -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .filter-applied {
        position: relative;
        background-color: #fff;
        // margin-bottom: 16px;
        margin-top: 16px;
        border-radius: 3px;

        .align-right {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }

        ul {
            display: flex;
            align-items: center;
            flex-flow: row wrap;
            padding-right: 116px;
            position: relative;

            li {
                display: inline-block;
                margin: 0 16px 16px 0;
                padding: 0 10px 3px;
                border-radius: 200px;
                border: 1px solid $color_text;
                position: relative;
                padding-right: 28px;
                padding-top: 4px;
                @include elementStyles($color: $color_title,
                    $weight: $mediumFont );

                & span {
                    margin-left: 5px;
                    color: $color_text;
                    font-weight: $regularFont;
                    word-break: break-word;
                }

                .filter-close {
                    margin-left: 7px;
                    cursor: pointer;
                    @include transitionForAll($time: 0.3s);
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    -o-transform: translateY(-50%);

                    &:hover {
                        transform: scale(1.3) translateY(-50%);
                        -webkit-transform: scale(1.3) translateY(-50%);
                        -moz-transform: scale(1.3) translateY(-50%);
                        -ms-transform: scale(1.3) translateY(-50%);
                        -o-transform: scale(1.3) translateY(-50%);
                    }
                }

                &.clear-filter {
                    border: 1px solid $color_yellow;
                    color: $color_yellow;
                    padding: 3px 10px;
                    margin: 0;
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                    font-size: 12px;
                }
            }
        }
    }

    .custom-scrollbar {
        overflow: auto;

        &::-webkit-scrollbar {
            width: 4px;
            cursor: pointer;
            height: 4px;
        }

        &::-webkit-scrollbar-track {
            color: #C4C8C9;
            background: #F2F2F2;
            border-radius: 6px;
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background: #C4C8C9;
            height: 20px;
        }
    }

    .MuiExpansionPanel-root {
        box-shadow: none;

        .MuiExpansionPanelSummary-root {
            width: 100%;
            min-height: unset;
            font-family: 'Roboto';
            font-size: 14px;
            padding: 0;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 10px;
        }

        .MuiExpansionPanelSummary-expandIcon {
            margin-right: 5px;
            padding: 0;
        }
    }
}

.custom-tablecell {
    display: table-cell;
    vertical-align: inherit;

    .MuiCheckbox-root {
        padding: 12px 10px 11px;
    }
}

.daterange-picker-wrapper {
    position: absolute;
    right: 0;
    top: -5px;
    min-width: 185px;
}
.custom-tablecell {
    display: table-cell;
    vertical-align: inherit;
    .MuiCheckbox-root {
        padding: 12px 10px 11px;
    }
}
.daterange-picker-wrapper {
    position: absolute;
    right: 0;
    top: -5px;
    min-width: 185px;
}
.custom-daterange-picker {
    &.e-preset-wrapper{
        min-width: 540px !important;
        background-color: #00698F;
        border-radius: 4px;
        overflow: hidden;
    }
    &.e-rtl {
        direction: ltr;

        .e-date-range-container .e-left-container {
            border-left: 0;
        }
    }

    .e-presets {
        max-width: 110px !important;

        .e-list-parent {
            .e-list-item {
                padding: 0 24px 0 10px;
            }
        }
    }

    .e-date-range-container {
        background-color: #fff;

        .e-range-header {
            margin: 15px 16px 15px 16px;
            height: 25px;

            .e-start-end {
                height: 20px;

                .e-start-label,
                .e-end-label {
                    font-size: 16px;
                    color: #00698F;
                    font-weight: 500;
                    line-height: 14px;
                }

                .e-change-icon {
                    &::before {
                        content: '\e85f';
                        color: #00698F;
                    }
                }
            }

            .e-day-span {
                font-size: 12px;
            }
        }

        .e-calendar-container {
            .e-calendar {
                direction: ltr;
                max-width: 220px !important;
                min-width: 220px !important;

                .e-header {
                    padding-top: 5px;

                    .e-prev {
                        float: left !important;

                        .e-date-icon-prev {
                            &::before {
                                content: '\e921' !important;
                            }
                        }
                    }

                    .e-next {
                        float: right !important;

                        .e-date-icon-next {
                            &::before {
                                content: '\e913' !important;
                            }
                        }
                    }

                    .e-title {
                        @include elementStyles($size: 12px,
                            $weight: 500,
                            $color: #49535D,
                        )
                    }

                    .e-icons {
                        color: #CACC4F;
                    }
                }

                .e-content {
                    table {
                        thead {
                            tr {
                                th {
                                    @include elementStyles($size: 12px,
                                        $weight: 500,
                                        $color: #49535D,
                                    )
                                }
                            }
                        }

                        tbody {
                            tr {
                                td {
                                    padding: 1px;

                                    &.e-cell {
                                        span {
                                            &.e-day {
                                                max-width: 100%;
                                                max-height: 100%;
                                                @include elementStyles($size: 12px,
                                                    $weight: 400,
                                                    $color: #68737A,
                                                )
                                            }
                                        }
                                    }

                                    &.e-selected {
                                        span {
                                            &.e-day {
                                                color: #fff !important;
                                                background: #00698F;
                                                font-weight: 500;
                                                // border-radius: 4px 0px 0px 4px;

                                            }
                                        }
                                    }

                                    &.e-range-hover {
                                        background: rgba(0, 105, 143, 0.1);

                                        span {
                                            &.e-day {
                                                color: #00698F;
                                                background: rgba(0, 105, 143, 0.1);
                                                font-weight: 500;
                                            }
                                        }
                                    }

                                    &.e-today {
                                        span {
                                            &.e-day {
                                                border: 1px solid #00698F !important;
                                                font-weight: 500;
                                            }
                                        }
                                    }

                                    &.e-other-month {
                                        background: #fff;
                                    }

                                    &.e-start-date {
                                        // border-radius: 50% 0 0 50%;
                                        border-radius: 4px 0px 0px 4px !important;
                                        background: #00698F;
                                    }

                                    &.e-end-date {
                                        border-radius: 0px 4px 4px 0px !important;
                                        background: #00698F;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .e-footer {
        background: #F3F4F4 !important;
        flex-direction: row-reverse !important;
        justify-content: flex-start !important;

        .e-btn {
            height: 30px;
            text-transform: capitalize;
            border-radius: 200px;
            font-weight: 400 !important;
            font-size: 12px !important;
        }

        .e-cancel {
            border: 1px solid #00698F !important;
            color: #00698F !important;
            background: transparent !important;

        }

        .e-apply {
            background: #00698F !important;
            border: 1px solid #00698F;
            color: #fff !important;
        }
    }

    &.e-control-wrapper {
        input {
            direction: ltr;
            color: #49535D !important;
            font-size: 13px !important;
            min-height: 20px !important;
            font-weight: 500 !important;
        }

        border-color: transparent !important;

        &::before,
        &::after {
            background: transparent !important;
        }

        .e-input-group-icon {
            color: #49535D !important;
            position: relative;
            top: 3px;
        }
    }

    .e-presets {
        background: #00698F !important;

        ul {
            background: #00698F;

            li {
                @include elementStyles($fontFamily: 'Roboto',
                    $size: 12px !important,
                    $weight: 400 !important,
                    $color: #fff,
                    $lineHeight: 14px);
                text-align: left;

                &.e-active {
                    color: #CACC4F !important;
                    font-weight: 500 !important;
                    background: #00698F !important;
                }

                @include transitionForAll($time: 0.4s);

                &:hover {
                    // background-color: #fff !important;
                    // color: #00698F !important;
                }
            }
        }
    }
}

.custom-switch-wrapper {
    background: rgba(73, 83, 93, 0.12);
    border-radius: 100px;
    padding: 4px 5px;
    color: #D41C54;
    font-size: 12px;
    line-height: 14px;
    width: 50px;
    box-sizing: border-box;
    margin-right: 5px;
    cursor: pointer;
    @include transitionForAll($time: 0.4s);

    span {
        width: 11px;
        height: 11px;
        display: inline-block;
        border-radius: 50%;
        background: #D41C54;
        position: relative;
        top: 2px;
        margin-right: 7px;
    }

    &.yes {
        color: $color_primary;

        span {
            background: $color_primary;
            margin-right: 0;
            margin-left: 5px;
        }
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
    }
}

.default-dialog {
    .MuiDialog-paper {
        margin-left: 200px;
        border-radius: 0;

        .MuiDialogContent-root {
            padding: 30px;
        }
    }
}

.page-content {
    box-sizing: border-box;
    padding: 20px 48px;
    width: 100%;
}