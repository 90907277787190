@import 'variable';

body{
  @include elementStyles(
    $fontFamily: 'Roboto',
    $lineHeight: 14px,
    $weight: $regularFont,
    $size:$font12,
    $color: $color_text,
  );
    *{
      padding: 0;
      margin: 0;
    }
    .text-center{
      text-align: center
    }
    .list-unstyled{
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .list-inline{
      list-style: none;
      li{
        display: inline-block;
      }
    }
    .flex-centered{
      display: flex;
      display: -webkit-flex;
      flex-flow: wrap;
      -webkit-flex-flow: wrap;
      align-items: center;
      &:before,&:after{
        width: 0;
      }
    }

    .flex-not-centered{
      display: flex;
      display: -webkit-flex;
      flex-flow: wrap;
      -webkit-flex-flow: wrap;
      &:before,&:after{
        width: 0;
      }
    }
    .background-prop{
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .flex-item{

    /* this */
    flex: 1 100px;

    /* is the same as */
    flex-grow: 1;
    flex-basis: 100px;

    /* and it leaves the flex-shrink property alone, which would be */
    flex-shrink: inherit; /* defaults to 1 */

    }
  }

  .form-group-field{
    position: relative;
    margin-bottom: 15px;
    .MuiFormControl-marginNormal{
      margin: 0;
    }
    .MuiOutlinedInput-input{
      padding: 10px !important;
    }
    .MuiInputBase-root.Mui-disabled{
      opacity: 0.4;
      input{
        color: #000;
      }
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
      border-color: $color_0091B3;
    }
    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
      border-color: #f44336;
    }
    .MuiInput-underline:after{
      border-bottom: 2px solid $color_0091B3 ;
    }
    .MuiOutlinedInput-multiline{
      padding: 0px;
    }
    .css-2b097c-container{
      width: 100%;
      .makeStyles-input-179{
        margin-top: -6px;
      }
    }
    .MuiFormControl-root{
      width: 100%;
      background: rgba(192, 204, 212, 0.15);
    }
    .MuiSelect-select{
      padding-top:5px;
      padding-left: 5px;
    }
    .field-wrapper{
      display: flex
    }
    .MuiInputBase-input{
      font-family: 'Roboto';
      font-size: 12px;
      color: $color_text;
      font-weight: $mediumFont;
      &:hover{
        outline: none;
        // border: 1px solid #000;
        // box-shadow: 0 6px 6px 0 rgba(0, 171, 173, 0.09);
      }
      &:focus{
        border-radius: 4px;
      }
    }
    .MuiFormLabel-root{
      font-size: 12px;
      font-family: "Roboto";
      // padding-top: 5px;
    }
    .MuiInputLabel-formControl{
      top: 3px;
      left: 5px;
    }
    .MuiInputAdornment-positionEnd{
      padding-right: 10px
    }
    .MuiFormLabel-root.Mui-focused{
      color: $color_0091B3
    }
    .MuiFormLabel-root.Mui-error {
      color: #f44336;
    }
    .MuiOutlinedInput-adornedEnd{
      padding-right: 3px !important;
    }
    .dropdown-selection{
       width: 100%;
       .css-yk16xz-control, .css-1pahdxg-control{
        background: transparent !important;
      }
      >div{
        // border: none;
        // border-bottom: 1px solid #a1a1a1;
        border-radius: 3px;
        width: 100%;
        cursor: pointer;
        font-family: 'Roboto' !important;
        font-size: 12px !important;
        // background: #F1F4F6;
        padding: 1px 0 ;
        &:hover{
          outline: none;
          // border: 1px solid #000;
          // box-shadow: 0 6px 6px 0 rgba(0, 171, 173, 0.09);
        }
      }&:focus, &:active >div{
          border-radius: 4px;
          // border: 1px solid #009B7C ;
          // box-shadow: 0 6px 6px 0 rgba(0, 171, 173, 0.09);
          outline: none;
          &:hover{
            border: none;
            outline: none
          }
      }
      &.field-error{
        >div{
          // border: 1px solid #e12525;
        }
      }
      .css-26l3qy-menu {
        *{
          cursor: pointer !important;
        }
      }
    }
    .MuiTextField-root{
      background: rgba(192, 204, 212, 0.15);
    }
    input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="url"], input[type="tel"],input[type="textarea"]{
      width: 100%;
      display: block;
      font-size: 12px;
      color: $color_text;
      font-weight: $mediumFont;
      font-family: 'Roboto';
      outline: none;
      padding: 6px 5px 8px !important;
      &:focus, &:hover, &:active{
        outline: none;
      }
      &.field-error{
        border: 1px solid #e12525;
        &:focus{
          outline: none;
          border: 1px solid #e12525;
        }
      }
    }
    textarea{
      padding-left: 5px;
    }
    .MuiAutocomplete-inputRoot{
      .MuiAutocomplete-input{
        padding-bottom: 7px !important;
      }
    }

    .react-phone-number-input{
      width: 100%;

      .rrui__options-list{
        // width: 255px !important;
        // max-height: 200px !important;
        ul{
          background: #0091B3 !important;
          color: #fff !important;
          .rrui__list__item{
            &:hover{
              background: #0091B3 !important;
              color: #fff !important;
            }
          }
          .rrui__list__item--selected,
          .rrui__list__item--selected:hover {
            background-color: rgba(255, 255, 255, 0.5) !important;
            color: #ffffff !important;
            font-weight: 500;
          }
        }
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 400;
        z-index: 1000 !important;
      }

      .rrui__select__button, .rrui__select__native{
        background: none;
      }
      .rrui__select__arrow{
        color: #000;
      }
      input{
        height: 46px !important;
        border-bottom: 1px solid #929090;
        background: rgba(192, 204, 212, 0.15);
        &:hover{
          border-bottom: 2px solid #000;
        }
        &:focus,&:active{
          outline: none;
          border-bottom: 2px solid #0091b3;
        }

      }
      .error{
        border-bottom: 2px solid #e12525;
        &:focus, &:active, &:hover{
          border-bottom: 2px solid #e12525;
          &:hover{
            border-bottom: 2px solid #e12525;
          }
        }
      }
    }
    .error-msg{
      // position: absolute;
      font-size: 11px;
      color: #f44336;
      font-family: 'Roboto';
      margin-top: 5px;
      font-weight: 400;
    }
    input[type="submit"]{
      // width: 100%;
      padding: 8px 22px;
      font-weight: $regularFont;
      background: $color_primary;
      font-family: $font_Roboto;
      box-shadow: none;
      outline: none;
      border: none;
      cursor: pointer;
      border-radius: 200px;
      font-size: $font12;
      color: #ffffff;
      &:disabled{
        pointer-events: none;
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }

  input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px rgba(242,242,242,0.8) inset !important;
}
input:-internal-autofill-selected {
  background: none !important;
}
.MuiInput-underline:after{
  border-bottom: 2px solid $color_0091B3 !important;
}
.MuiInput-underline.Mui-error:after{
  border-bottom-color: #f44336 !important
}
.MuiSelect-select{
  background: transparent !important;
}
.check-box-span{
  .MuiCheckbox-colorSecondary.Mui-checked:hover{
    background-color: transparent;
  }
  .MuiIconButton-colorSecondary:hover {
    background-color: transparent;
  }
 .payment-check-box{
   padding: 15px 10px 15px 0px;
   span{
    color: $color_0091B3 !important;
   }
 }
 span{
   font-family: 'Roboto';
   line-height: 14px;
   font-size: 12px;
   font-weight: 400;
   color: $color_text;
   a{
      text-decoration: none;
      color: $color_0091B3 !important;
      font-weight: 500 !important;
      font-size: 12px !important;

    }
   }
}
//autocomplete styling
.MuiAutocomplete-root{
  width: 100%;
  font-family: 'Roboto';
}
.MuiAutocomplete-noOptions{
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
}
.MuiAutocomplete-listbox{
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  background: #0091B3;
  color: #fff !important;
  max-height: 160px !important;
}
.MuiAutocomplete-option{
  // cursor: pointer !important;
  &:hover{
    // color: #ffff;
    // background-color: #67737A !important;

  }
}
.MuiAutocomplete-option[aria-selected="true"]{
  background-color: rgba(255, 255, 255, 0.5) !important;
  color: #fff;
  font-weight: 500;
}

.makeStyles-success{
  background-color: #005E70;
}
.MuiSnackbarContent-root{
  font-family: 'Roboto'
}
//dropdown-styling
.MuiMenu-list, .MuiAutocomplete-listbox{
  background: #0091B3;
  max-height: 200px;
  overflow: auto;
  box-shadow: none;
}
.MuiMenuItem-root {
  padding: 2px 12px;
  font-family: 'Roboto' !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 29px;
}
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
  background-color: #00748F !important;
  color: #fff;
  font-weight: 500;
}

a{
  text-decoration: none;
}
.primary-btn, .secondary-btn{
  padding: 8px 22px;
  border-radius: 200px;
  font-family: 'Roboto';
  font-size: $font12;
  cursor: pointer;
  font-weight: $regularFont;
  background: $color_primary;
  color: #fff;
  &.disabled{
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }
 }
.secondary-btn{
  padding: 7px 22px ;
  background: #FFF;
  border: 1px solid $color_primary;
  color: $color_primary;
}
.heading1{
  @include elementStyles(
    $fontFamily: 'Roboto',
    $lineHeight: 19px,
    $weight: $mediumFont,
    $size: $font16,
    $color: $color_title,
  );
}

.heading2{
  @include elementStyles(
    $fontFamily: 'Roboto',
    $lineHeight: 16px,
    $weight: $mediumFont,
    $size: $font14,
    $color: $color_title,
  );
  span{
    font-weight: $regularFont;
  }
  &.light{
    font-weight: $regularFont;
  }
}

.paragraph{
  @include elementStyles(
    $fontFamily: 'Roboto',
    $lineHeight: 14px,
    $weight: $regularFont,
    $size:$font12,
    $color: $color_text,
  );
  span{
    font-weight: $mediumFont
  }
}
.secondaryText{
  @include elementStyles(
    $fontFamily: 'Roboto',
    $lineHeight: 14px,
    $weight: $regularFont,
    $size:$font12,
    $color: $color_text,
  );
}