
$font_Roboto: 'Roboto', sans-serif;

$font12: 12px;
$font14: 14px;
$font16: 16px;
$font18: 18px;
$font20: 20px;
$font24: 24px;
$font32: 32px;
$font36: 36px;
$font48: 48px;

$color_title: #49535D;
$color_text: #68737A;
$color_primary: #00698F;
$color_yellow: #CACC4F;
$color_7D858C: #7D858C;
$color_secondary: #AEAF44;
$color_0091B3: #0091B3;
$color_red: #D41C54;
$color_footer: #4F575C;

$lightFont:300;
$regularFont:400;
$mediumFont:500;
$semiBoldFont:600;
$boldFont:700;

@mixin elementStyles($size: null, $color: null,  $lineHeight: null, $weight: null, $fontFamily: null, $margin: null, $padding: null){
    @if $size != null {
        font-size: $size;
    }
    @if $lineHeight != null {
        line-height: $lineHeight;
    }
    @if $weight != null {
        font-weight: $weight;
    }
    @if $color != null {
        color: $color;
    }
    @if $fontFamily != null {
        font-family: $fontFamily;
    }
    @if $margin != null {
        margin: $margin;
    }
    @if $padding != null {
        padding: $padding;
    }
}

@mixin transitionForAll($time: 0.2s,$transProp: ease){
    -webkit-transition: all $time $transProp;
    -moz-transition: all $time $transProp;
    -o-transition: all $time $transProp;
    transition: all $time $transProp;
    transition: all $time $transProp;
}