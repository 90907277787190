@import '../../../../assets/styles/variable';

body {
    .brand-details-section {
        .brand-details-container {
            padding: 0;

            .top-blk {
                margin-bottom: 5px;

                .back-btn {
                    cursor: pointer;

                    img {
                        margin-right: 3px;

                    }

                    .paragraph {
                        color: #C4C8C9
                    }
                }

                .button-wrapper {
                    justify-content: flex-end;

                    button img+span {
                        margin-left: -3px;
                    }
                }
            }

            .title {
                text-align: center;
                margin-bottom: 40px;

                h3 {
                    font-size: 18px;
                    line-height: 21px;
                    margin-bottom: 16px;
                    word-break: break-word;
                }

                p {
                    font-weight: $regularFont;
                    word-break: break-word;
                }
            }

            ul.basic {
                max-width: 80%;
                margin: 0 auto;
                margin-bottom: 30px;

                li {
                    // margin-right: 40px;
                    width: 50%;
                    padding: 0 30px 15px 130px;
                    box-sizing: border-box;

                    &:nth-child(even) {
                        border-left: 1px solid rgba(73, 83, 93, 0.1);
                        padding: 0 0 15px 70px;
                    }

                    .heading2 {
                        &.entityType {
                            color: $color_yellow;

                            span {
                                color: $color_title;
                            }
                        }

                        span {
                            font-weight: $regularFont;
                            margin-right: 5px;
                        }

                        font-weight: $boldFont;

                        img {
                            position: static !important;
                        }
                    }

                    &:last-child {
                        margin: 0;
                        padding-bottom: 0;
                    }
                }
            }

            .brand-basic-details {
                margin: 0 auto 32px auto;

                .left-blk,
                .middle-blk,
                .right-blk {
                    // border-left:  1px solid rgba(73, 83, 93, 0.1);
                    height: 100%;
                    box-sizing: border-box;
                    margin: 0 auto;
                    width: 70%;
                }

                .middle-blk {
                    border: 1px solid rgba(73, 83, 93, 0.1);
                    border-top: none;
                    border-bottom: none;
                    width: 100%;
                    padding-left: 15%;
                    padding-right: 10%;
                }

                .heading2 {
                    margin-bottom: 16px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.entityType {
                        color: $color_yellow;

                        span {
                            color: $color_title;
                        }
                    }

                    &.mb-4 {
                        margin-bottom: 4px;
                    }

                    span {
                        font-weight: $regularFont;
                        margin-right: 5px;
                    }

                    font-weight: $boldFont;

                    img {
                        position: static !important;
                    }

                    .universal-ein-link {
                        color: $color_0091B3;
                        font-weight: 700;
                        margin-right: 3px;
                    }
                }
            }

            .details {
                border: 1px solid #C0CCD4;
                padding: 15px 20px;
                margin-bottom: 20px;

                .details-heading {
                    position: relative;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #C0CCD4;
                    margin-bottom: 15px;

                    h3 {
                        line-height: 21px;
                    }

                    img {
                        position: absolute;
                        top: 2px;
                        left: 0;
                        width: 18px;
                    }

                    &.campaigns-listing {
                        margin-bottom: 0;

                        h3 {
                            position: absolute;
                        }

                        .filter-section {
                            .filter-button {
                                float: right;

                                img {
                                    top: unset;
                                    left: 15px;
                                }
                            }
                        }
                    }
                    &.border-none {
                        border: none;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }

                .detail-item {
                    h3 {
                        word-break: break-word;

                        span {
                            font-weight: $semiBoldFont
                        }
                    }
                }

                &.campaigns-listing {
                    padding-bottom: 15px;
                    overflow-x: auto;

                    .details-heading {
                        padding-bottom: 0;
                        border-color: transparent;
                    }

                    .tcr-status {
                        span {
                            font-weight: 400;
                        }
                    }

                    .mno-operational-status {
                        position: relative;

                        span {
                            font-weight: 400;
                        }
                    }

                    .filters {
                        margin-top: 12px;
                        .MuiAutocomplete-root {
                            width: auto;
                        }
                    }

                    .empty-row {
                        height: 240px !important;
                    }

                    .pagination-wrapper {
                        margin-top: 10px;

                        .pagination {
                            margin-top: 0;
                            padding-top: 15px;
                            margin: 0 -20px;
                            border-top: 1px solid #C0CCD4;
                        }
                    }

                    .campaign-btn {
                        cursor: pointer;
                        padding: 8px 15px 8px 32px;
                        margin-right: 8px;
                        position: relative;
                        border-radius: 200px;
                        color: #fff;
                        text-align: left;

                        &.disabled {
                            pointer-events: none;
                            cursor: not-allowed;
                            opacity: 0.6;
                        }

                        display: inline-block;

                        img {
                            position: absolute;
                            left: 12px;
                            top: 7px;
                        }

                        &.suspend {
                            background: #D41C54;
                        }

                        &.lift {
                            background: #0091B3;
                            padding: 8px 15px 8px 34px;

                            img {
                                top: 8px;
                            }
                        }
                    }
                }

                &.remove-vetting-container-style {
                    border: none !important;
                    padding: 0px !important;
                    margin-bottom: 0px !important;
                }

                &.vetting {
                    .details-heading {
                        border-bottom: none;
                        padding-bottom: 0;
                    }

                    .vetting-listing-table {
                        width: 100%;
                        .outcome {
                            .view-icon {
                                cursor: pointer;
                                margin-left: 5px;
                                display: inline-block;
                                border-radius: 50%;
                                background: transparent;
                                position: relative;

                                &.active {
                                    background: rgba(0, 145, 179, 0.1);
                                }
                            }
                        }

                        .empty-row {
                            height: 160px;
                        }
                    }
                }

                .vetting-details-tooltip {
                    display: flex;
                    flex-direction: column;
                    max-height: 355px;
                    cursor: default;

                    .title {
                        font-weight: $boldFont;
                        background-color: $color_primary;
                        padding: 10px 20px;
                        padding: 10px 20px;
                        color: white;
                        line-height: 20px;
                        margin-bottom: 0;
                        text-align: left;
                        font-size: $font20;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                    }

                    .reasons-container {
                        width: 100%;
                        overflow-y: auto;
                        padding: 9px 17px 0px 17px;
                        box-sizing: border-box;
                    }

                    .copy-paste-container {
                        font-size: 12px;
                        padding: 9px 17px;

                        div {
                            p {
                                color: #071822 !important;

                                &.copy {
                                    color: #68737A;
                                    text-decoration: underline;

                                }
                            }
                        }
                    }

                    ul {
                        // padding: 20px 0;
                        max-height: 260px;
                        overflow: auto;

                        li {
                            margin-bottom: 4px;
                            // padding: 0 20px;
                            color: #071822 !important;
                            word-break: break-word;
                            list-style: disc;
                            display: list-item;
                            margin-left: 1em;

                            &.no-style {
                                list-style: none;
                                margin-left: 0em;
                            }

                            cursor: text;

                            span {
                                font-weight: $boldFont;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &::-moz-selection {
                                color: white;
                                background: $color_0091B3;
                            }

                            &::selection {
                                color: white;
                                background: $color_0091B3;
                            }
                        }
                    }
                }
            }

            .modal {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 430px;
                height: 226px;
                background: #FFFFFF;
            }
        }
    }

    .cv-token-details {
        .MuiDialog-paper .MuiDialogContent-root {
            padding: 0;
        }

        .MuiPaper-root {
            max-width: 320px;
            margin: 32px;

            .dialog-header {
                background-color: $color_primary;

                .dialog-title {
                    padding: 10px 20px;
                    @include elementStyles($fontFamily: 'Roboto',
                        $lineHeight: 20px,
                        $weight: 700,
                        $size: 12px,
                        $color: #FFFFFF,
                    );

                    img {
                        position: absolute;
                        right: 16px;
                        top: 14px;
                        height: 12px;
                    }
                }
            }

            .dialog-body {
                padding: 10px 20px;
                max-height: calc(100vh - 200px);
                overflow: auto;

                ul {
                    li {
                        @include elementStyles($fontFamily: 'Roboto',
                            $lineHeight: 22px,
                            $weight: 400,
                            $size: 12px,
                            $color: $color_primary,
                        );
                        margin-bottom: 2px;

                        &::-moz-selection {
                            /* Code for Firefox */
                            color: #FFFFFF;
                            background: $color_primary;
                        }

                        &::selection {
                            color: #FFFFFF;
                            background: $color_primary;
                        }
                    }
                }
            }

            .footer {
                padding: 16px;
                text-align: center;

                li {
                    a {
                        display: inline-block;
                    }
                }

                // li{}
                // margin-bottom: 0;
                // padding-top: 15px;
                // input[type="submit"]{
                //     max-width: 80px;
                // }
            }
        }
    }
}