@import '../../../../assets/styles/variable';

@mixin TableWrapper {
    .tableWrapper {
        table {
            tbody {
                tr {
                    .event-type-wrapper {
                        position: relative;

                        p {
                            font-weight: 500;
                        }
                    }

                    .MuiPaper-root {
                        background-color: transparent;
                    }

                    .MuiExpansionPanel-root {
                        .MuiExpansionPanelSummary-root {
                            padding: 0;
                            .MuiExpansionPanelSummary-expandIcon.MuiIconButton-edgeEnd{
                                height: 13px;
                            }
                        }

                        .MuiExpansionPanelSummary-root.Mui-expanded {
                            line-height: 1.5;
                            word-break: break-all;
                        }

                        .MuiExpansionPanelSummary-content {
                            color: #68737A;
                            margin: 0;
                            font-size: 12px;
                            @include transitionForAll($time: 0.3s);
                            word-break: break-all;
                        }
                    }

                    .events-listing-table-link {
                        cursor: pointer;

                        &:hover, &:active {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

body {
    .events-listing-section {
        .events-listing-container {
            padding: 0;

            .title-block {
                display: flex;
                margin-bottom: 15px;
                h2 {
                    font-size: 27px;
                    font-weight: 600;
                    color: #19262A;
                    line-height: 32px;
                }
                span {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    margin-left: 12px;
                    margin-bottom: 5px;
                    align-self: end;
                }
            }
            .filters {
                margin-top: 12px;
                .MuiAutocomplete-root {
                    width: auto;
                }
            }

            @include TableWrapper;
        }
    }

    .events-filter-wrapper {
        .filters {
            .MuiAutocomplete-root {
                width: auto;
            }
            .e-date-range-wrapper {
                border: 0 !important;
            }
        }
    }
    .events-table-wrapper {
        @include TableWrapper;
    }
}