@import 'variable';

.btn-s{
    border: 1px solid !important;
    height: 23px !important;
    padding: 4px 17px !important;
    font-size: $font12 !important;
    font-weight: $regularFont !important;
}
.btn-transparent {
    background: transparent !important;
}