@import '../../../assets/styles/variable';

body{
    .user-authentication-section{
        .auth-banner{
          margin: 30px 20px 0 20px;
          background: #fff;
          .content-wrapper{
            background: #c4c8c9;
            padding: 20px 20px 0px 40px;
            .paragraph{
              @include elementStyles(
                $fontFamily: 'Roboto Condensed',
                $lineHeight: 18px,
                $weight: 400,
                $size:14px,
                $color: #67737a,
                $margin: 0 0 22px 0px,
              );
            }
          }
          .footer-wrapper{
            position: relative;
            background: #c4c8c9;
            min-height: 35vh;
            border-radius: 0 0 0 100%;
            margin-top: -5px;
            .inner-wrapper{
              position: absolute;
              bottom: 50px;
              right: 30px;
              p{
                @include elementStyles(
                  $fontFamily: 'Roboto Condensed',
                  $lineHeight: 18px,
                  $size: 10px,
                  $color: #67737a,
                )
              }
            }
          }
        }
        .user-form-wrapper{
          min-height: 85vh;
          height: 100%;
          
          &.auth-thanks{
            background-color: #fff;
            background-clip: border-box;
            overflow: hidden;
          }
        }
        .auth-user-form{
          padding: 20px 20px 25px;
          width: 420px;
          box-sizing: border-box;
          background-color: #ffffff;
          border: 1px solid #E5E5E5;
          input[type="submit"]{
            max-width: 90px;
          }
          .form-title{
            margin-bottom: 20px;
            text-align: center;
            img{
              position: relative;
              width: 23px;
              top: 6px;
              margin-right: 5px;
            }
            h3{
              @include elementStyles(
                $margin: 0 0 16px 0
              );
            }
            .paragraph{
              width: 80%;
              margin: 0 auto;
            }
          }
          &.reset-password, &.forgot-password{
            padding-bottom: 20px;
            .form-title{
              margin-bottom: 30px;
              img{
                width: 20px;
                height: 25px;
              }
            }
            input[type="submit"]{
              margin: 15px 0 0 0;
            }
          }
        }
        .account-link-option{
          margin: 30px 0 0 0;
          padding-top: 20px;
          border-top: 1px solid #C0CCD4;
          color: $color_7D858C;
          font-size: 12px;
          a{
            @include elementStyles(
              $color: $color_yellow,
              $weight: $boldFont,
              $size: $font12
            )
          }
          &.forgotpwd{
            text-align: right;
            margin-top: 20px;
            padding-top: 0px;
            border: none;
            margin-bottom: 30px;
            a{
              color: #0091b3;
              font-weight: $regularFont;
            }
          }
        }
        .campaign-registry{
          text-align: center;
          .header-img{
            padding-top: 40px;
          }
          .link{
            margin-top: 40px;
          }
          .content-blk{
            width: 520px;
            background: rgba(202, 204, 79, 0.1);
            border: 1px solid $color_yellow;
            padding: 30px;
            img{
              width: 50px;
              margin-bottom: 12px;
            }
            h4{
              @include elementStyles(
                $color: $color_secondary,
                $margin: 0 0 5px 0
              );
            }
            .heading1{
              &.text{
                width: 350px;
                margin: 0 auto;
                @include elementStyles(
                  $color: $color_secondary,
                  $weight: $regularFont
                );
              }
            }
            a{
              display: inline-block;
            }
            span{
              font-weight: 700;
            }
            .spam{
              @include elementStyles(
                $fontFamily: 'Roboto',
                $lineHeight: 22px,
                $weight: 400,
                $size:$font14,
                $color: $color_secondary,
                $padding: 10px 0 0 0,
              );
            }
            .account-link-option{
              font-size: 20px;
              color: #fff;
              margin-top: 80px;
              a{
                font-size: 20px;
              }
            }
          }
        }
      }
    .customTooltipWrapper{
      padding: 20px;
      background: rgba(0, 105, 143, 0.15);;
      margin: 0;
      box-shadow: none;
      border-radius: 8px;

      .bellIcon {
        position: absolute;
        right: -12px;
        top: -16px;
      }
      p {
        margin-bottom: 5px;
      }
      ul {
        li {
          padding: 4px 0;
          text-align: left;
          svg,
          span {
            vertical-align: middle;
          }
          svg {
            font-size: 16px;
            margin-right: 5px;
            // fill: $color_green;
            fill: #00d2ad;
            &.clearIcon {
              fill: $color_red;
            }
          }
        }
      }

      .list-suggestion {
        padding-left: 10px;
        li {
          margin-left: 4px;
        }
      }
      .secondaryText {
        color: #00698F;

        &.boldText {
          font-weight: $boldFont;
        }

        img {
          margin-right: 4px;
        }
        .red {
          color: $color_red;
        }
      }
    }
}